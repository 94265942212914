import { type InternalErrorCode as ReplenishmentBatchErrorCode } from '@autone/openapi-replenishment';
import { i18nAutone } from '@autone/translations';

import { type ReplenishmentTranslationFunction } from '../types/translations';

export type ReplenishmentBatchErrorTranslations = {
  header: string;
  description: string;
};

const translateReplenishmentBatchErrorCode = (
  key: Parameters<ReplenishmentTranslationFunction>[0],
) => i18nAutone.t(`replenishment:${key}`);

export const getTranslatedErrorCodes = (): Record<
  ReplenishmentBatchErrorCode,
  ReplenishmentBatchErrorTranslations
> => ({
  AUTO_SUBMISSION_FAILED: {
    header: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.auto-submission-failed.header',
    ),
    description: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.auto-submission-failed.header',
    ),
  },
  NO_NEED_AFTER_FILTERING_ON_IA: {
    header: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.no-need-after-filtering-on-ia.header',
    ),
    description: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.no-need-after-filtering-on-ia.description',
    ),
  },
  NO_INVENTORY_FOR_FROM_AND_TO_LOCATIONS: {
    header: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.no-inventory-for-from-and-to-locations.header',
    ),
    description: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.no-inventory-for-from-and-to-locations.description',
    ),
  },
  NO_INVENTORY_FOR_FROM_LOCATION: {
    header: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.no-inventory-for-from-location.header',
    ),
    description: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.no-inventory-for-from-location.description',
    ),
  },
  NO_DISTRIBUTION_FLOW: {
    header: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.no-distribution-flow.header',
    ),
    description: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.no-distribution-flow.description',
    ),
  },
  NO_DISTRIBUTION_FLOW_FOR_FROM_LOCATION: {
    header: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.no-distribution-flow-for-from-location.header',
    ),
    description: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.no-distribution-flow-for-from-location.description',
    ),
  },
  NO_TO_LOCATION: {
    header: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.no-to-location.header',
    ),
    description: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.no-to-location.description',
    ),
  },
  FROM_AND_TO_SAME_LOCATION: {
    header: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.from-and-to-same-location.header',
    ),
    description: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.from-and-to-same-location.description',
    ),
  },
  NO_PRODUCTS_IN_FROM_LOCATION: {
    header: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.no-products-in-from-location.header',
    ),
    description: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.no-products-in-from-location.description',
    ),
  },
  UNABLE_TO_CALCULATE_LOCATION_DEPTH: {
    header: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.unable-to-calculate-location-depth.header',
    ),
    description: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.unable-to-calculate-location-depth.description',
    ),
  },
  NO_SKUS: {
    header: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.no-skus.header',
    ),
    description: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.no-skus.description',
    ),
  },
  NO_NEED_OR_RECENT_ACTIVITY: {
    header: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.no-need-or-recent-activity.header',
    ),
    description: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.no-need-or-recent-activity.description',
    ),
  },
  NO_NEED: {
    header: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.no-need.header',
    ),
    description: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.no-need.description',
    ),
  },
  NO_COVERAGE: {
    header: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.no-coverage.header',
    ),
    description: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.no-coverage.description',
    ),
  },
  INTERNAL_ERROR: {
    header: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.internal-error.header',
    ),
    description: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.internal-error.description',
    ),
  },
  UNKNOWN: {
    header: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.unknown.header',
    ),
    description: translateReplenishmentBatchErrorCode(
      'new-replenishment.batch-error-codes.unknown.description',
    ),
  },
});
