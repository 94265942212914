import { useAutoneTranslation } from '@autone/translations';
import {
  type CustomTheme,
  DashboardSidebar,
  LanguageSelectionPopover,
  MenuIconButton,
  SideBarContent,
  useGetPagePadding,
  withLanguagePreferenceFeatureFlag,
} from '@autone/ui';
import { Stack, type Theme, useTheme } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import { type PropsWithChildren, useState } from 'react';

import { type APP_NAME } from '../constant';

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(() => ({
  flexGrow: 1,
  minHeight: '100%',
  overflow: 'auto',
}));

const MainStyleNext = styled('div')(
  ({
    theme,
    getPagePadding,
  }: {
    theme: CustomTheme;
    getPagePadding: () => number;
  }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'scroll',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: getPagePadding(),
    paddingRight: getPagePadding(),
  }),
);

const LanguageSelectionRenderer = withLanguagePreferenceFeatureFlag(
  LanguageSelectionPopover,
  'replenishment',
);

const SidebarContentWithPopover = () => {
  const { common } = useAutoneTranslation<typeof APP_NAME>();
  return (
    <SideBarContent
      renderItems={(isCollapse) => (
        <LanguageSelectionRenderer
          isCollapse={isCollapse}
          labelDict={{
            'en-US': common('language.label.english-us'),
            en: common('language.label.english'),
            it: common('language.label.italian'),
            fr: common('language.label.french'),
          }}
          errorText={common('language.error-text')}
        />
      )}
    />
  );
};

export function DashboardLayout({ children }: PropsWithChildren) {
  const [open, setOpen] = useState(false);

  return (
    <RootStyle>
      <MenuIconButton setOpen={setOpen} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      >
        <SidebarContentWithPopover />
      </DashboardSidebar>
      <MainStyle>{children}</MainStyle>
    </RootStyle>
  );
}

export const PageLayout = ({ children }: PropsWithChildren) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme<CustomTheme>();

  // TODO - move to package as this is used in Buying2 as well
  const { getPagePadding } = useGetPagePadding();

  return (
    <RootStyle>
      <Stack>
        <MenuIconButton setOpen={setOpen} />
        <DashboardSidebar
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
        >
          <SidebarContentWithPopover />
        </DashboardSidebar>
      </Stack>

      <MainStyleNext
        theme={theme as Theme & CustomTheme}
        getPagePadding={getPagePadding}
      >
        {children}
      </MainStyleNext>
    </RootStyle>
  );
};
